import {
    CLIENT_TYPE_INDIVIDUAL,
    CLIENT_TYPE_INSTITUTION,
  } from "../../../../constants/client";
  import { IFormField } from "../../../../utils/form_factory";
  
  export const GeneralFormFields =
    (formType: string) =>
    (clientType: string, isMember: boolean) =>
    (
      ClientTypes: any[],
      Gender?: any[],
      Countries?: any[],
    ): IFormField[] =>
      [
        // {
        //   name: "is_staff",
        //   initailValue: false,
        //   label: "Is Client a Staff Member?",
        //   type: "text",
        //   uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
        //   uiType: "checkbox",
        //   initailValueReadPath: "is_member",
        //   isHidden: formType === "Update",
        // },
        {
          name: "type",
          initailValue: "",
          label: "Client Type",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "select",
          options: ClientTypes,
          selector: {
            value: (option: any) => option.value,
            label: (option: any) => option.name,
          },
          isDisabled: true,
          isHidden: true,
          initailValueReadPath: "type",
        },    
        {
          name: "first_name",
          initailValue: "",
          label: "First Name",
          type: "text",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "text",
          isHidden:
            clientType === CLIENT_TYPE_INSTITUTION
        },
        {
          name: "last_name",
          initailValue: "",
          label: "Last Name",
          type: "text",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "text",
          isHidden:
            clientType === CLIENT_TYPE_INSTITUTION
        },
        {
          name: "gender",
          initailValue: "",
          label: "Gender",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "select",
          options: Gender,
          selector: {
            value: (option: any) => option.value,
            label: (option: any) => option.name,
          },
          isHidden:
            clientType === CLIENT_TYPE_INSTITUTION
        },
        {
          name: "email",
          initailValue: "",
          label: "Email Address",
          type: "text",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "text",
        },
        {
          name: "nationality",
          initailValue: "Uganda",
          label: "Nationality",
          type: "text",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "select",
          options: Countries,
          selector: {
            value: (option: any) => option.country,
            label: (option: any) => option.country,
          },
        },
        {
          name: "phone_number",
          initailValue: "256",
          label: "Phone Number",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "phone",
          customFormat: 'XXX-XXX-XXXXXX',
        },
        {
          name: "date_of_birth",
          initailValue: "",
          label: "Date of Birth",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "date",
        },
        {
          name: "country",
          initailValue: "Uganda",
          label: "Country",
          type: "text",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "select",
          options: Countries,
          selector: {
            value: (option: any) => option.country,
            label: (option: any) => option.country,
          },
        },
        {
          name: "city",
          initailValue: "",
          label: "City/District",
          type: "text",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "text",
        },
        {
          name: "address",
          initailValue: "",
          label: "Home Address",
          type: "text",
          uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
          uiType: "text",
        },
        
    ];
  
  export const RiskFormFields = (FUND_SOURCE?: any, RISK_LEVEL?: any): IFormField[] => [
    {
      name: "source_of_funds",
      initailValue: "",
      label: "Source of Funds",
      uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      uiType: "select",
      options: FUND_SOURCE,
      selector: {
        value: (option: any) => option.value,
        label: (option: any) => option.name,
      },
    },
    {
      name: "risk_level",
      initailValue: "",
      label: "Risk Level",
      uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      uiType: "select",
      options: RISK_LEVEL,
      selector: {
        value: (option: any) => option.value,
        label: (option: any) => option.name,
      },
    },
  ];
  
  export const AttachmentFormFields = (ID_TYPE?: any): IFormField[] => [
    {
      name: "id_type",
      initailValue: "",
      label: "ID Type",
      uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      uiType: "select",
      options: ID_TYPE,
      selector: {
        value: (option: any) => option.value,
        label: (option: any) => option.name,
      },
    },
    {
        name: "id_number",
        initailValue: "",
        label: "ID Number (NIN)",
        type: "text",
        uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
        uiType: "text",
    },
    {
        name: "id_photo",
        initailValue: "",
        label: "ID/Passport Photo",
        uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
        uiType: "file",
    },
    {
        name: "selfie",
        initailValue: "",
        label: "Selfie",
        uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
        uiType: "file",
    },
    {
        name: "password",
        initailValue: "",
        label: "Create Password",
        type: "password",
        uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
        uiType: "password",
    },
    {
        name: "confirm_password",
        initailValue: "",
        label: "Confirm Password",
        type: "password",
        uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
        uiType: "password",
    },
    
  ];
  