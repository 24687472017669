import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import FlexBox from "../../../components/FlexBox";
import LightOutlinedInput from "../../../components/LightOutlinedInput";
import LightTextField from "../../../components/LightTextField";
import { H1, Paragraph, Small } from "../../../components/Typography";
import useAuth from "../../../hooks/useAuth";
import { primary } from "../../../theme/themeColors";
import { ValidationSchema } from "./Validations";
import { dispatch } from "react";

const Login: FC = () => {
  const { login } = useAuth();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  let navigate = useNavigate();

  const initialValues = {
    password: "",
    email : "",
    remember: false,
  };

  const loginForm = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: async (values: any) => {
      handleSubmit(values.email, values.password);
    },
  });

  const handleSubmit = async (email: string, password: string) => {
    setLoading(true);
    try {
      await login(email, password)
      setLoading(false);
      navigate("/dashboard");
    } catch (error: any) {
      setError(error.message)
      setLoading(false);
    }
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <FlexBox
      data-aos="fade-up"
      data-aos-duration="800"
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: { sm: "100%" },
      }}
    >
      <Box sx={{ padding: 2 }}>
        <Stack spacing={1}>
          <H1 fontSize={28} fontWeight={800} mb={3}>
            <span style={{ color: primary.main }}>Sign</span> In
          </H1>
          <H1 fontSize={16} fontWeight={700}>
            Everpesa
          </H1>
          <Paragraph pb={3} fontSize={12}>
            Your Gateway to Financial Freedom.
          </Paragraph>
        </Stack>

        <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
          <form noValidate onSubmit={loginForm.handleSubmit} style={{ width: "100%" }}>
            <Stack spacing={2}>
              <TextFieldWrapper>
                <Paragraph fontWeight={600} mb={1}>
                  Email
                </Paragraph>
                <LightTextField
                  fullWidth
                  name="email"
                  type="email"
                  onBlur={loginForm.handleBlur}
                  onChange={loginForm.handleChange}
                  value={loginForm.values.email || ""}
                  error={Boolean(loginForm.touched.email && loginForm.errors.email)}
                  helperText={loginForm.touched.email && loginForm.errors.email}
                />
              </TextFieldWrapper>

              <TextFieldWrapper>
                <Paragraph fontWeight={600} mb={1}>
                  Password
                </Paragraph>
                <LightOutlinedInput
                  fullWidth
                  name="password"
                  onBlur={loginForm.handleBlur}
                  onChange={loginForm.handleChange}
                  value={loginForm.values.password || ""}
                  error={Boolean(loginForm.touched.password && loginForm.errors.password)}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffOutlined fontSize="small" />
                        ) : (
                          <VisibilityOutlined fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </TextFieldWrapper>
            </Stack>

            <FlexBox mt={2} alignItems="center" justifyContent="space-between">
              <FormControlLabel
                control={
                  <Switch
                    name="remember"
                    checked={loginForm.values.remember}
                    onChange={loginForm.handleChange}
                    sx={{ "& .MuiSwitch-thumb": { bgcolor: "#003399" } }}
                  />
                }
                label="Remember Me"
                sx={{ "& .MuiTypography-root": { fontWeight: 600 } }}
              />
              <Link to="/forgot-password">
                <Small color="secondary.red">Forgot Password?</Small>
              </Link>
            </FlexBox>

            {error && (
              <FormHelperText
                error
                sx={{
                  mt: 2,
                  fontSize: 13,
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                {error}
              </FormHelperText>
            )}

            <Box sx={{ mt: 4 }}>
              {loading ? (
                <LoadingButton
                  loading
                  fullWidth
                  variant="contained"
                  sx={{ bgcolor: "#003399" }}
                >
                  Sign In
                </LoadingButton>
              ) : (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ bgcolor: "#003399" }}
                >
                  Sign In
                </Button>
              )}
            </Box>
          </form>

          <Small margin="auto" mt={3} color="text.disabled">
            Don't have an account?{" "}
            <Link to="/register">
              <Small color="primary.main">Create an everpesa account Now!</Small>
            </Link>
          </Small>
        </FlexBox>
      </Box>
    </FlexBox>
  );
};

export default Login;

const TextFieldWrapper = styled.div`
  width: 100%;
`;
