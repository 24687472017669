import { USER_TYPES } from "../../constants/user-types";
import Icons from "../../icons/sidebar";

export interface IMenuItem {
  title: string;
  Icon?: any;
  path: string;
  subMenu?: IMenuItem[];
  permissions?: string[];
  users?: string[];
}

const MenuList: IMenuItem[] = [
  {
    title: "Home",
    Icon: Icons.HomeIcon,
    path: "/dashboard",
  },
  {
    title: "Invest",
    Icon: Icons.MonetizationOnIcon,
    path: "/dashboard/invest-grid",
  },
  {
    title: "Asset", //portfolio - cash
    Icon: Icons.RequestQuoteIcon,
    path: "/dashboard/asset-grid",
  },
  {
    title: "SACCO",
    Icon: Icons.SavingsIcon,
    path: "/dashboard/sacco-grid",
  },
  // {
  //   title: "Cash",
  //   Icon: Icons.AccountBalanceWalletIcon,
  //   path: "/dashboard/sacco-grid",
  // },
  {
    title: "Admin",
    Icon: Icons.AdminPanelSettingsIcon,
    path: "/admin/invest",
    // users: [USER_TYPES.CLIENT, USER_TYPES.SYSTEM_ADMIN],
  },
  {
    title: "Clients",
    Icon: Icons.GroupsIcon,
    path: "/clients",
    //users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    subMenu: [
      {
        path: "/clients/individual",
        title: "Individual",
      },
      {
        path: "/clients/institution",
        title: "Institution",
      },
    ],
  },
];

export default MenuList;
