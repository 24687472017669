import instance from "../../../../api";


export const RegisterService = {
  async Register(payload: Object) {
    console.log(payload);
    return instance.post("users/", payload).then((response) => response.data);
  },

  //takes in token and new password and resets the password. input =  {"password": "string","token": "string"}
  async confirmPasswordReset(payload: Object) {
    console.log(payload);
    return instance.post(`password_reset/confirm/`, payload).then((response) => response.data);
  },
};
