import React, { FC, useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Box, Button, Card, Stack, FormHelperText } from "@mui/material";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import FlexBox from "../../../components/FlexBox";
import LightTextField from "../../../components/LightTextField";
import { H1, Small, Paragraph } from "../../../components/Typography";
import { PasswordResetService } from "./services/PasswordReset.service";
import { LoadingButton } from "@mui/lab";
import { ValidationSchema } from "./models/Validations";
import { t } from "i18next";
import { styled } from "styled-components";
import { primary } from "../../../theme/themeColors";

const ResetPassword: FC = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [tokenValidated, setTokenValidated] = useState(false);
  const { token } = useParams<{ token?: string }>();
  const navigate = useNavigate(); // Use navigate to handle routing

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
    submit: null,
  };

  const resetPasswordForm = useFormik({
    initialValues,
    validationSchema : ValidationSchema,
    onSubmit: async (values: any) => {
      handleFormSubmit(values);
    },
  });

  const handleFormSubmit = async (values: any) => {
    setLoading(true);
    try {
      if (tokenValidated) {
        // Send a request to reset the password with the token
        const response = await PasswordResetService.confirmPasswordReset({
          token,
          newPassword: values.newPassword,
        });

        if (response.valid) {
          toast.success("Password has been reset successfully!");
          setLoading(false);
          setError("");
        } else {
          // Set error based on the API response
          setError(response.error);
          setLoading(false);
        }
      } else {
        setError("Token not validated"); // Update the error message for unvalidated token
        setLoading(false);
      }
    } catch (error: any) {
      // Set error based on the API response
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const validatePasswordToken = async (token: string) => {
      try {
        const response = await PasswordResetService.validatePasswordResetToken({
          token,
        });
        if (response.valid) {
          setTokenValidated(true);
          setLoading(false);
        } else {
          // Set error based on the API response
          setError(response.error);
          setLoading(false);
        }
      } catch (error: any) {
        // Set error based on the API response
        setError(error.message);
        setLoading(false);
      }
    };

    if (token) {
      validatePasswordToken(token);
    } else {
      setError("Token not provided"); // Update the error message for missing token
      setLoading(false);
    }
  }, [token]);

  const renderPasswordFields = () => {
    if (tokenValidated) {
      // Render the password fields and form for validated token
      return (
        <div>
          <LightTextField
            fullWidth
            name="newPassword"
            type="password"
            label="New Password"
            onBlur={resetPasswordForm.handleBlur}
            onChange={resetPasswordForm.handleChange}
            value={resetPasswordForm.values.newPassword || ""}
            error={Boolean(resetPasswordForm.touched.newPassword && resetPasswordForm.errors.newPassword)}
            helperText={resetPasswordForm.touched.newPassword && resetPasswordForm.errors.newPassword}
          />

          <LightTextField
            fullWidth
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            onBlur={resetPasswordForm.handleBlur}
            onChange={resetPasswordForm.handleChange}
            value={resetPasswordForm.values.confirmPassword || ""}
            error={Boolean(
              resetPasswordForm.touched.confirmPassword && resetPasswordForm.errors.confirmPassword
            )}
            helperText={
              resetPasswordForm.touched.confirmPassword && resetPasswordForm.errors.confirmPassword
            }
            style={{ marginTop: "16px" }}
          />

          {error && (
            <FormHelperText
              error
              sx={{
                mt: 2,
                fontSize: 13,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {error}
            </FormHelperText>
          )}

          <Box sx={{ mt: 4 }}>
            {loading ? (
              <LoadingButton loading fullWidth variant="contained">
                Reset Password
              </LoadingButton>
            ) : (
              <Button fullWidth type="submit" variant="contained">
                Reset Password
              </Button>
            )}
          </Box>
        </div>
      );
    } else if (loading) {
      return <div>Loading...</div>;
    } else {
      // Render appropriate UI for unvalidated token
      return (
        <div>
          <FormHelperText
            error
            sx={{
              mt: 2,
              fontSize: 13,
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {error}
          </FormHelperText>
          <Button
            fullWidth
            variant="contained"
            onClick={() => navigate("/forgot-password")} // Use navigate for routing
          >
            Return to Forgot Password
          </Button>
        </div>
      );
    }
  };

  return (
    <FlexBox
      data-aos="fade-up"
      data-aos-duration="800"
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: { sm: "100%" },
      }}
    >
      <Card sx={{ padding: 4, maxWidth: 600, marginTop: 4, boxShadow: 1 }}>
        <Box sx={{ padding: 2 }}>
          <Stack spacing={1}>
            <Box width={38} mb={1} justifyContent="center">
              <img src="/static/logo/user.png" width="100%" alt="Zimba Logo" />
            </Box>
            <H1 fontSize={28} fontWeight={800} mb={3}>
              <span style={{ color: primary.main }}>Reset your</span> Password
            </H1>
            <Paragraph pb={3} fontSize={12}>
              Enter your new password and confirm it to reset your password.
            </Paragraph>
          </Stack>

          <FlexBox justifyContent="space-between" flexWrap="wrap" my={2}>
            <form noValidate onSubmit={resetPasswordForm.handleSubmit} style={{ width: "100%" }}>
              {renderPasswordFields()}
            </form>

            <Small margin="auto" mt={3} color="text.disabled">
              Remember your password?{" "}
              <Link to="/login">
                <Small color="primary.main">Log in</Small>
              </Link>
            </Small>
          </FlexBox>
        </Box>
      </Card>
    </FlexBox>
  );
};

export default ResetPassword;
