import React, { FC, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { useTheme } from '@emotion/react';
import { deepDerefrencer } from '../../../utils/form_factory';
import uniqueId from '../../../utils/generateId';

interface IFormPhoneInputProps {
    name: string;
    label: string;
    formControl: any;
    customFormat?: string;
}

const FormPhoneInput: FC<IFormPhoneInputProps> = ({ name, label, formControl, customFormat }) => {
    const theme = useTheme();

    // for handling phone number input
    const formatNumber = (value: string) => {
        const stringToFormat = value || '';

        const numericValue = stringToFormat.replace(/\s/g, ''); // remove spaces
        if (customFormat) {
            let formatted = '';
            let customIndex = 0;

            for (let i = 0; i < customFormat.length; i++) {
                if (customFormat[i] === 'X') {
                    formatted += numericValue[customIndex] || '';
                    customIndex++;
                } else {
                    formatted += customFormat[i];
                }
            }

            return formatted;
        }

        // Standard formatting
        return numericValue.replace(/(\d{3})-(\d{3})-(\d{6})/, '$1 $2 $3'); // 256-777-777777
    }

    const [formattedValue, setFormattedValue] = React.useState<string>('');

    useEffect(() => {
        setFormattedValue(formatNumber(deepDerefrencer(formControl.values, name) || '256'))
    }, [name, customFormat, deepDerefrencer(formControl.values, name)])

    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;

        const numericValue = input.replace(/\D/g, '');

        const formattedInput = formatNumber(numericValue)
        setFormattedValue(formattedInput);

        if (customFormat && numericValue.length > customFormat.replace(/[^X]/g, '').length) {
            return;
        }

        const phoneInput = numericValue.replace(/\s/g, '').replace(/-/g, '')
        formControl.setFieldValue(name, phoneInput);
    }

    return (
        <TextField
            sx={styles.customStyles(theme)}
            fullWidth id={uniqueId()}
            label={label} variant="outlined"
            name={name}
            type={'text'}
            onChange={handleNumberChange}
            value={formattedValue}
            error={Boolean(
                deepDerefrencer(formControl.touched, name) && deepDerefrencer(formControl.errors, name)
            )}
            helperText={
                deepDerefrencer(formControl.touched, name) && deepDerefrencer(formControl.errors, name)
            }
            onBlur={formControl.handleBlur}
        />
    )
}

const styles = {
    customStyles: (theme: any) => ({
        "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "8px",
            border: "2px solid",
            borderColor:
                theme.palette.mode === "light"
                    ? theme.palette.secondary[300]
                    : theme.palette.divider,
        },

        "& input[type='file'].MuiInputBase-input": {
            marginLeft: "120px",
        },
    })
}

export default FormPhoneInput;