import Slider from "react-slick";
import styled from "styled-components";
import { Car , Wallet, Finance, Saving , Money, Education, ChickenFarm, ClimateChange, FarmersMarket, Farming, Forest, GlobalWarming } from "../../assets/svg/SvgIcons";

const settings = {
  // fade: true,
  autoplay: true,
  speed: 1000,
  pauseOnHover: false,
  autoplaySpeed: 6000,
  slidesToShow: 1,
  arrows: false,
  dots: true,
};

function InfoTile({ data }: any) {
  const { title, desc, image, width } = data;
  return (
    <Wrapper className="whiteColor flexCenter flexColumn textCenter gap20">
      <SliderImg src={image} alt="image" width={width} />

      <div className="flexCenter flexColumn gap20" style={{ maxWidth: 500 }}>
        <h2>{title}</h2>
        <div className="font13">{desc}</div>
      </div>
    </Wrapper>
  );
}

const infoData = [
  {
    image: Finance,
    title: "Smart Investments, Bright Futures",
    desc: (
      <p>
        Explore a world of investment opportunities with Everpesa. From Treasury bills 
        and bonds to real estate, our platform offers diverse options for every investor. 
        Grow your wealth intelligently, supported by our user-friendly interface.
      </p>
    ),
  },
  {
    image: ClimateChange,
    title: "Combat Climate Change Together",
    desc: (
      <p>
        Take a stand against climate change by investing in initiatives that 
        promote environmental sustainability. Your contributions make a 
        difference in the fight against climate change, creating a better 
        future for generations to come.
      </p>
    ),
  },
  {
    image: Wallet,
    title: "Your Digital Wallet, Your Way",
    desc: (
      <p>
        Empower your finances with Everpesa's advanced digital wallet. Manage your 
        money effortlessly, make secure transactions, and enjoy real-time insights 
        into your spending. Your financial freedom starts here.
      </p>
    ),
  },
  {
    image: Farming,
    title: "Invest in Sustainable Farming Practices",
    desc: (
      <p>
        Be part of the movement towards sustainable farming. Your investment 
        in farming initiatives encourages environmentally friendly practices, 
        enhances food security, and promotes a healthier relationship between 
        agriculture and nature.
      </p>
    ),
  },
  {
    image: Money,
    title: "Earn More, Live More",
    desc: (
      <p>
        Unleash the potential to earn with Everpesa. Discover opportunities 
        to grow your income, whether through investments, referrals, or special 
        promotions. Maximize your wealth through our integrated money exchange and 
        transfer services, making it convenient to send and receive money worldwide.
      </p>
    ),
  },
  // {
  //   image: Car,
  //   title: "Loan Opportunities Await You",
  //   desc: (
  //     <p>
  //       Unlock financial possibilities with Everpesa's robust loan services. 
  //       Experience hassle-free borrowing, competitive interest rates, and a 
  //       seamless application process. Your financial goals are just a click away.
  //     </p>
  //   ),
  // },
  // {
  //   image: Saving,
  //   title: "Effortless Saving, Endless Possibilities",
  //   desc: (
  //     <p>
  //       Start your journey towards financial security with Everpesa's innovative 
  //       saving solutions. Whether short-term goals or long-term plans, we provide a 
  //       simple and effective way to build your savings. Your dreams, our priority.
  //     </p>
  //   ),
  // },
  {
    image: Education,
    title: "Empower Yourself through Financial Literacy",
    desc: (
      <p>
        Everpesa believes in the power of knowledge. Dive into our educational 
        resources for financial literacy, equipping yourself with the skills to 
        make informed decisions. Learn, grow, and take control of your financial future.
      </p>
    ),
  },
  {
    image: ChickenFarm,
    title: "Support Sustainable Agriculture",
    desc: (
      <p>
        Invest in green farming and contribute to sustainable agriculture. 
        Your investment helps promote ethical and environmentally friendly 
        practices in the industry. Join us in fostering a greener and 
        healthier food ecosystem.
      </p>
    ),
  },
  
  {
    image: FarmersMarket,
    title: "Empower Local Farmers and Markets",
    desc: (
      <p>
        Support local farmers and markets through sustainable investments. 
        By choosing FarmersMarket, you contribute to the growth of local 
        economies, promote fair trade, and ensure the availability of fresh, 
        quality produce for everyone.
      </p>
    ),
  },
  {
    image: Forest,
    title: "Preserve and Regenerate Forests",
    desc: (
      <p>
        Contribute to the preservation and regeneration of forests worldwide. 
        Forest investments play a crucial role in combating deforestation, 
        protecting biodiversity, and mitigating the impacts of climate change. 
        Join us in creating a sustainable and green future.
      </p>
    ),
  },
  {
    image: GlobalWarming,
    title: "Address Global Warming Head-On",
    desc: (
      <p>
        Fight global warming through strategic investments. Your commitment to 
        initiatives addressing global warming helps fund projects focused on 
        reducing greenhouse gas emissions and building a resilient and 
        sustainable future for the planet.
      </p>
    ),
  },
  
];

export default function InfoSlider() {
  return (
    <>
      {/* Slider */}
      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        style={{ height: "100%" }}
      >
        <Slider {...settings}>
          {infoData.map((val, key) => {
            return <InfoTile data={val} key={key} />;
          })}
        </Slider>
      </div>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 600px;
  padding: 40px;
`;

const SliderImg = styled.img`
  width: ${(props) => (props.width ? props.width : "350px")};
  marginbottom: 20px;
  @media (max-width: 760px) {
    width: ${(props) => (props.width ? props.width : "100%")};
  }
`;
