import { Navigate } from "react-router-dom";
import RouteGuard from "../guards/RouteGuard";
import AuthGuard from "../components/authentication/AuthGuard";
import GuestGuard from "../components/authentication/GuestGuard";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import { LoginPortal } from "../pages/Authentication/LoginPortal";
import LazyLoader from "./routes-loader"
import ResetPassword from "../pages/Authentication/ResetPassword/ResetPassword";
import ForgotPassword from "../pages/Authentication/ForgotPassword/ForgotPassword";
import { CLIENT_TYPE_INDIVIDUAL, CLIENT_TYPE_INSTITUTION } from "../constants/client";

const routes = [
    {
        path: "/",
        element: <Navigate to="dashboard" />,
    },
    {
        path: "login",
        element: (
            <GuestGuard>
                <LoginPortal />
            </GuestGuard>
        ),
    },
    {
        path: "register",
        element: (
            <GuestGuard>
                <LoginPortal />
            </GuestGuard>
        ),
    },
    {
        path: "forgot-password",
        element: (
            <GuestGuard>
                <LoginPortal />
            </GuestGuard>
        ),
    },
    {
        path: "reset-password",
        element: (
            <GuestGuard>
                <ResetPassword />
            </GuestGuard>
        ),
    },
    {
        path: "dashboard",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "",
                element: <LazyLoader.DashboardSaaS />,
            },
            {
                path: "user-profile",
                element: <LazyLoader.UserProfile />,
            },
            {
                path: "invest-grid",
                element: <LazyLoader.InvestGrid />,
            },
            {
                path: "invest-grid/details/:investId",
                element: <LazyLoader.InvestDetail />,
                requiresAuth: true,
            },
            {
                path: "sacco-grid",
                element: <LazyLoader.SaccoGrid />,
            },
            {
                path: "sacco-grid/details/:saccoId",
                element: <LazyLoader.SaccoDetail/>,
                requiresAuth: true,
            },
            {
                path: "asset-grid",
                element: <LazyLoader.AssetGrid />,
            },
        ],
    },
    {
        path: "forbidden",
        element: <LazyLoader.Error403 />,
    },
    {
        path: "*",
        element: <LazyLoader.Error404 />,
    },

    {
        path: "admin",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "invest",
                element: <LazyLoader.Invest />,
                requiresAuth: true,
            },
            {
                path: "invest/create",
                element: <LazyLoader.InvestForm />,
                requiresAuth: true,
            },
            {
                path: "invest/details/:investId",
                element: <LazyLoader.InvestDetailAdmin />,
                requiresAuth: true,
            },
            // {
            //     path: "sacco",
            //     element: <LazyLoader.Sacco />,
            //     requiresAuth: true,
            // },
            // {
            //     path: "sacco/create",
            //     element: <LazyLoader.SaccoForm />,
            //     requiresAuth: true,
            // },
        ],
    },
    {
        path: "clients",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "individual",
                element: <LazyLoader.Clients client_type={CLIENT_TYPE_INDIVIDUAL} title={"Individuals"} />,
            },
            {
                path: "institution",
                element: <LazyLoader.Clients client_type={CLIENT_TYPE_INSTITUTION} title={"Individuals"} />,
            },
            {
                path: "create-client",
                element: <LazyLoader.ClientForm />,
            },
            {
                path: "client-profile/:clientId",
                element: <LazyLoader.ClientProfile />,
            },
        ],
    },
    {
        path: "account",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "profile",
                element: <LazyLoader.UserProfile />,
                requiresAuth: true,
            },
        ],
    }
];

export default routes;