import { Grid } from "@mui/material";
import { FC } from "react";
import FormTextInput from "./FormComponents/FormTextInput";
import FormSelectInput from "./FormComponents/FormSelectInput";
import FormCheckbox from "./FormComponents/FormCheckBox";
import { IFormField, deepDerefrencerValidations } from "../../utils/form_factory";
import { FormDateInput } from "./FormComponents/FormDateInput";
import FormTextArea from "./FormComponents/FormTextArea";
import FormPhoneInput from "./FormComponents/FormPhoneInput";
import FormFileInput from "./FormComponents/FormFileInput";
import FormSelectSearchInput from "./FormComponents/FormSelectSearch";
import { string } from "yup";

interface IFormFactoryProps {
    formikInstance: any;
    formFields: IFormField[];
    validationSchema?: any;
    others?: any;
}

const FormTypeFactory = (formField: IFormField, formikInstance: any, validationSchema: any, others: any) => {
    const isFieldRequired = (fieldName: string) => {
        return deepDerefrencerValidations(validationSchema, fieldName)
            ?.tests?.some((test: any) => test.OPTIONS.name === 'required');
    }

    switch (formField.uiType) {
        case 'text':
            return <FormTextInput
                {...formField}
                type={formField.type}
                formControl={formikInstance}
                label={`${formField.label} ${isFieldRequired(formField.name) ? '*' : ''}`}
            />
        case 'phone':
            return <FormPhoneInput
                {...formField}
                formControl={formikInstance}
                label={`${formField.label} ${isFieldRequired(formField.name) ? '*' : ''}`}
            />
        case 'file':
            return <FormFileInput
                {...formField}
                formControl={formikInstance}
                label={`${formField.label} ${isFieldRequired(formField.name) ? '*' : ''}`}
            />
        case 'date':
            return <FormDateInput
                {...formField}
                format={others.format}
                formControl={formikInstance}
                label={`${formField.label} ${isFieldRequired(formField.name) ? '*' : ''}`}
            />
        case 'select':
            return <FormSelectInput
                {...formField}
                formControl={formikInstance}
                label={`${formField.label} ${isFieldRequired(formField.name) ? '*' : ''}`}
            />
        case 'select-search':
            return <FormSelectSearchInput
                {...formField}
                formControl={formikInstance}
                label={`${formField.label} ${isFieldRequired(formField.name) ? '*' : ''}`}
            />
        case 'checkbox':
            return <FormCheckbox
                formControl={formikInstance}
                {...formField}
                // check if label is a string or a react node
                label={typeof formField.label === 'string' ? `${formField.label} ${isFieldRequired(formField.name) ? '*' : ''}` : formField.label}
            />
        case 'textarea':
            return <FormTextArea formControl={formikInstance}
                {...formField} label={`${formField.label} ${isFieldRequired(formField.name) ? '*' : ''}`} />
        default:
            return <FormTextInput
                formControl={formikInstance}
                {...formField} label={`${formField.label} ${isFieldRequired(formField.name) ? '*' : ''}`}
            />
    }
}

const FormFactory: FC<IFormFactoryProps> = ({ formFields, formikInstance, validationSchema, others }) => {
    return (
        <Grid {...others} container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {
                formFields.map((field: IFormField, index: number) => {
                    return (
                        <Grid sx={field.isHidden ? styles.hidden : {}} key={index} item {...field.uiBreakpoints}>
                            {FormTypeFactory(field, formikInstance, validationSchema, { ...others, format: field.dateFormat })}
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

export default FormFactory;

const styles = {
    hidden: {
        display: 'none'
    }
}