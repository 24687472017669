import React, { useState, FC } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Box, Button, Card, FormHelperText, Stack, } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FlexBox from "../../../components/FlexBox";
import LightTextField from "../../../components/LightTextField";
import { H1, Small, Paragraph } from "../../../components/Typography";
import { PasswordResetService } from "./services/PasswordReset.service";
import { validationSchema } from "./models/Validations";
import { styled } from "styled-components";
import { primary } from "../../../theme/themeColors";
import toast from "react-hot-toast";


const ForgotPassword: FC = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: "demo@example.com",
    submit: null,
  };

  const forgotPasswordForm = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values : any) => {
      handleSubmit(values.email);
    },
  });


  const handleSubmit = async (email: string) => {

    setLoading(true);
    try {
      const response = await PasswordResetService.passwordReset({
        email: email,
      });
      // Handle the API response here if needed
      setLoading(false);
      setError("");
      toast.success("Reset link has been sent!");
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <FlexBox
      data-aos="fade-up"
      data-aos-duration="800"
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: { sm: "100%" },
      }}
    >
      <Card sx={{ padding: 4, maxWidth: 600, marginTop: 4, boxShadow: 1 }}>
          <Box sx={{ padding: 2 }}>
            <Stack spacing={1}>
              <H1 fontSize={28} fontWeight={800} mb={3}>
                <span style={{ color: primary.main }}>Reset your</span> Password
              </H1>
              <Paragraph pb={3} fontSize={12}>
                Enter your email address, and we will send you a link to reset your
                password.
              </Paragraph>
            </Stack>

            <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
              <form noValidate onSubmit={forgotPasswordForm.handleSubmit} style={{ width: "100%" }}>
                <Stack spacing={2}>
                  <TextFieldWrapper>
                    <Paragraph fontWeight={600} mb={1}>
                      Email
                    </Paragraph>
                    <LightTextField
                      fullWidth
                      name="email"
                      type="email"
                      onBlur={forgotPasswordForm.handleBlur}
                      onChange={forgotPasswordForm.handleChange}
                      value={forgotPasswordForm.values.email || ""}
                      error={Boolean(forgotPasswordForm.touched.email && forgotPasswordForm.errors.email)}
                      helperText={forgotPasswordForm.touched.email && forgotPasswordForm.errors.email}
                    />
                    {error && (
                      <FormHelperText
                        error
                        sx={{
                          mt: 2,
                          fontSize: 13,
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        {error}
                      </FormHelperText>
                    )}

                    <Box sx={{ mt: 4 }}>
                      {loading ? (
                        <LoadingButton loading fullWidth variant="contained">
                          Reset
                        </LoadingButton>
                      ) : (
                        <Button fullWidth type="submit" variant="contained">
                          Reset
                        </Button>
                      )}
                    </Box>
                  </TextFieldWrapper>
                </Stack>
              </form>
              <Small margin="auto" mt={3} color="text.disabled">
                Don't have an account?{" "}
                <Link to="/login">
                  <Small color="primary.main">Contact Everpesa Team Now</Small>
                </Link>
              </Small>
            </FlexBox>
          </Box>

      </Card>
    </FlexBox>
  );
}

export default ForgotPassword;

const TextFieldWrapper = styled.div`
  width: 100%;
`;
