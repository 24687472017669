import { lazy, Suspense as ReactSuspense } from "react";
import LoadingScreen from "../components/LoadingScreen";

const Suspense: any = ReactSuspense;

const Loadable = (Component: any) => (props: any) => {
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    );
};

const RouteLazyLoader = {
    // AUTHENTICATION ROUTES
    Login: Loadable(
        lazy(() => import("../pages/Authentication/Login/Login"))
    ),
    ForgotPassword: Loadable(
        lazy(() => import("../pages/Authentication/ForgotPassword/ForgotPassword"))
    ),
    ResetPassword: Loadable(
        lazy(() => import("../pages/Authentication/ResetPassword/ResetPassword"))
    ),
   
    // DASHBOARD ROUTES
    DashboardSaaS: Loadable(
        lazy(() => import("../pages/dashboards/SaaS"))
    ),

    // USER MANAGEMENT ROUTES
    UserProfile: Loadable(
        lazy(() => import("../pages/UserProfile/UserProfile"))
    ),

    // USER ROUTES
    AssetGrid: Loadable(
        lazy(() => import("../pages/Asset/AssetGrid"))
    ),
    InvestGrid: Loadable(
        lazy(() => import("../pages/Invest/InvestGrid"))
    ),
    InvestDetail: Loadable(
        lazy(() => import("../pages/Invest/InvestDetail"))
    ),
    SaccoGrid: Loadable(
        lazy(() => import("../pages/Sacco/SaccoGrid"))
    ),
    SaccoDetail: Loadable(
        lazy(() => import("../pages/Sacco/SaccoDetail"))
    ),

    // ADMIN ROUTES
    Invest: Loadable(
        lazy(() => import("../pages/Admin/Invest/Invest"))
    ),
    InvestForm: Loadable(
        lazy(() => import("../pages/Admin/Invest/InvestForm"))
    ),
    InvestDetailAdmin: Loadable(
        lazy(() => import("../pages/Admin/Invest/InvestDetail"))
    ),
    Clients: Loadable(
        lazy(() => import("../pages/Admin/Clients/Clients"))
    ),
    ClientForm: Loadable(
        lazy(() => import("../pages/Admin/Clients/ClientsForm"))
    ),
    ClientProfile: Loadable(
        lazy(() => import("../pages/Admin/Clients/ClientProfile"))
    ),
    // ERROR ROUTES
    Error404: Loadable(lazy(() => import("../pages/404"))),
    Error403: Loadable(lazy(() => import("../pages/403"))),
}

export default RouteLazyLoader;