import * as Yup from "yup";

export const GeneralInfoValidations = Yup.object().shape({
    // is_staff
    // type
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    gender: Yup.string().nullable(),
    email: Yup.string().email("Invalid email"),
    nationality: Yup.string().required("Nationality is required"),
    phone_number: Yup.string().required("Phone number is required"),
    date_of_birth: Yup.string().required("Date of birth is required"),
    country: Yup.string().nullable(),
    city: Yup.string().nullable(),
    address: Yup.string().nullable(),
});

export const RiskInfoValidations = Yup.object().shape({
    source_of_funds: Yup.string().required("Source of Investment funds is required"),
    risk_level: Yup.string().required("Risk Level is required"),
});

export const AttachmentInfoValidations = Yup.object().shape({
    id_type : Yup.string().required("ID Type is required"),
    id_number: Yup.string().required("ID/Passport number is required"),
    id_photo: Yup.string().nullable(),
    selfie: Yup.string().nullable(),
    password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters"),
        // .matches(
        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
        //   "Password must contain at least one uppercase letter, one lowercase letter, and one number"
        // ),
    confirm_password: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const FilterFormValidations = Yup.object().shape({});

// id_number
// profile_picture
// id_picture_front
// id_picture_back
