import { FC, useEffect, useState } from "react";
import { Box, Button, Card, useMediaQuery, useTheme, Stack } from "@mui/material";
import useTitle from "../../../hooks/useTitle";
import { useNavigate } from "react-router-dom";
import HorizontalStepper, { IStep } from "../../../components/UI/HorizontalStepper";
import { AttachmentFormFields, RiskFormFields, GeneralFormFields } from "./models/RegisterFormFields";
import { deepMerge, getInitialValues } from "../../../utils/form_factory";
import { useFormik } from "formik";
import FormFactory from "../../../components/UI/FormFactory";
import { RegisterService } from "./services/Register.service";
import { toast } from "react-hot-toast";
import { H4, Span } from "../../../components/Typography";
import ProgressIndicator from "../../../components/UI/ProgressIndicator";
import { AttachmentInfoValidations, GeneralInfoValidations, RiskInfoValidations } from "./models/Validations";
import { CLIENT_TYPES } from "../../../constants/client";
import { AlertMessage } from "../../../components/UI/AlertMessage";
import { H1, Paragraph, Small } from "../../../components/Typography";
import { primary } from "../../../theme/themeColors";
import { CURRENCY_CODES } from "../../../constants/currency-codes";
import { GENDER } from "../../../constants/gender";
import { FUND_SOURCE } from "../../../constants/fund-source";
import { RISK_LEVEL } from "../../../constants/risk-level";
import { ID_TYPE } from "../../../constants/id-type";

const Register: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const formatDateToYYYYMMDD = (date) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useTitle("Register");

  const [loading, setLoading] = useState<boolean>(false);
  const [isMember, setIsMember] = useState<boolean>(false);
  const [formsErrorStates, setFormsErrorStates] = useState<any>({
    generalInfoForm: true,
    riskInfoForm: true,
    attachmentsInfoForm: true,
  });
  const [nonFieldErrors, setNonFieldErrors] = useState<any[]>([]);

  const generalFormFields = GeneralFormFields('Save')('', isMember)(CLIENT_TYPES, GENDER, CURRENCY_CODES);
  const riskFormFields = RiskFormFields(FUND_SOURCE, RISK_LEVEL);
  const attachmentsFormFields = AttachmentFormFields(ID_TYPE);
  
  const handleSubmit = async () => {
    try {
      if (generalInfoForm.values.password !== generalInfoForm.values.confirm_password) {
        toast.error("Passwords do not match");
        return;
      }
  
      setLoading(true);

      // Convert date_of_birth to the required format
      const formattedDateOfBirth = formatDateToYYYYMMDD(generalInfoForm.values.date_of_birth);
  
      // Group profile fields
      const formData = {
        ...generalInfoForm.values, 
        ...riskInfoForm.values,
        ...attachmentsInfoForm.values,
        profile: {
          first_name: generalInfoForm.values.first_name,
          last_name: generalInfoForm.values.last_name,
          date_of_birth: formattedDateOfBirth,
          gender: generalInfoForm.values.gender,
        },
      };
  
      // Remove grouped fields from the top level
      // delete formData.first_name;
      // delete formData.last_name;
      // delete formData.date_of_birth;
      // delete formData.gender;

  
      await RegisterService.Register(formData);
  
      setLoading(false);
      toast.success('Client created successfully');
      handleReset();
      navigate("/login");
    } catch (error: any) {
      setLoading(false);
      toast.error('Failed to create Client');
      const { non_field_errors, ...errors } = error.response.data;
  
      if (non_field_errors) {
        setNonFieldErrors(non_field_errors.length > 0 ? non_field_errors : []);
      }
  
      setFormsErrorStates({
        generalInfoForm: setFormErrors(errors, generalInfoForm, getInitialValues(generalFormFields)),
        riskInfoForm: setFormErrors(errors, riskInfoForm, getInitialValues(riskFormFields)),
        attachmentsInfoForm: setFormErrors(errors, attachmentsInfoForm, getInitialValues(attachmentsFormFields)),
      });
    }
  };
  

  const generalInfoForm = useFormik({
    initialValues: getInitialValues(generalFormFields),
    validationSchema: GeneralInfoValidations,
    onSubmit: handleSubmit,
  });

  const riskInfoForm = useFormik({
    initialValues: getInitialValues(riskFormFields),
    validationSchema: RiskInfoValidations,
    onSubmit: handleSubmit,
  });

  const attachmentsInfoForm = useFormik({
    initialValues: getInitialValues(attachmentsFormFields),
    validationSchema: AttachmentInfoValidations,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    generalInfoForm.setFieldValue('country', 'Uganda');
  }, []);

  useEffect(() => {
    if (generalInfoForm.values.is_member) {
      setIsMember(true);
    } else {
      setIsMember(false);
      generalInfoForm.setFieldValue('client_details.account_type', '');
    }
  }, [generalInfoForm.values.is_member]);

  const stepCollection = [
    {
      label: 'Personal',
      formControl: generalInfoForm,
      formFields: generalFormFields,
      validations: GeneralInfoValidations,
      formErrorState: formsErrorStates.generalInfoForm,
    },
    {
      label: 'Risk Profile',
      formControl: riskInfoForm,
      formFields: riskFormFields,
      validations: RiskInfoValidations,
      formErrorState: formsErrorStates.riskInfoForm,
    },
    {
      label: 'Attachments',
      formControl: attachmentsInfoForm,
      formFields: attachmentsFormFields,
      validations: AttachmentInfoValidations,
      formErrorState: formsErrorStates.attachmentsInfoForm,
    },
  ];

  const getForm = () => {
    return stepCollection
      .map(({ formControl }: any) => formControl.values)
      .reduce((acc: any, curr: any) => deepMerge(acc, curr), {});
  };
  

  const handleReset = () => {
    generalInfoForm.resetForm();
    riskInfoForm.resetForm();
    attachmentsInfoForm.resetForm();
  };

  const steps = (forms: any[]): IStep[] => {
    return forms.map(({ label, formControl, formFields, validations, formErrorState }: any) => ({
      label,
      formControl,
      formErrorState,
      component: (
        <Box sx={{ width: '100%' }}>
          <FormFactory
            others={{ sx: { marginBottom: '0rem' } }}
            formikInstance={formControl}
            formFields={formFields}
            validationSchema={validations}
          />
        </Box>
      ),
    }));
  };

  const SubmitBtn = () => (
    <Button onClick={handleSubmit} size="small" variant="contained" sx={{ marginLeft: '0.5rem' }}>
      {loading ? (
        <>
          <ProgressIndicator color="inherit" size={20} />{' '}
          <Span style={{ marginLeft: '0.5rem' }} color="primary">
            Loading...
          </Span>
        </>
      ) : (
        'Register'
      )}
    </Button>
  );

  return (
    <Box pt={2} pb={4}>
      <Card sx={{ padding: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          {nonFieldErrors.length > 0 && (
            <Box>
              <AlertMessage
                isOpen={nonFieldErrors.length > 0}
                message={
                  <ul style={{ padding: 0, margin: 0 }}>
                    {nonFieldErrors.map((error: string, index: number) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                }
                type="error"
                closeAlert={() => setNonFieldErrors([])}
              />
            </Box>
          )}
          <Box sx={styles.cardHeader}>
            <Stack spacing={1}>
              <H1 fontSize={28} fontWeight={800} mb={3}>
                <span style={{ color: primary.main }}>Register</span>
              </H1>
              <H1 fontSize={16} fontWeight={700}>
                Let's get to know you
              </H1>
            </Stack>
            <Button onClick={() => navigate(-1)} variant="contained" color="primary" size="small">
              Back
            </Button>
          </Box>
          <Box sx={styles.stepperWrapper}>
            <HorizontalStepper steps={steps(stepCollection)} submitBtn={<SubmitBtn />} />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

const styles = {
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  stepperWrapper: {
    width: '100%',
    overflowX: 'auto',
    '& .MuiStep-horizontal': {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
};

export default Register;