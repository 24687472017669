import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  styled,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import topMenuList from "./MenuList";

// root component interface
interface SideNavBarProps {
  showMobileSideBar: boolean;
  closeMobileSideBar: () => void;
}

// custom styled components
const MainMenu = styled(Box)(({ theme }) => ({
  left: 0,
  width: 90,
  height: "100%",
  position: "fixed",
  boxShadow: theme.shadows[2],
  transition: "left 0.3s ease",
  zIndex: theme.zIndex.drawer + 11,
  backgroundColor: theme.palette.primary.main, // Set the background color to primary
  [theme.breakpoints.down("md")]: { left: -80 },
}));

const BottomMenu = styled(BottomNavigation)<{ hidden: boolean }>(
  ({ theme, hidden }) => ({
    width: "100%",
    position: "fixed",
    bottom: hidden ? "-64px" : "0", // Hide/show the bottom menu
    boxShadow: theme.shadows[2],
    zIndex: theme.zIndex.drawer + 11,
    backgroundColor: theme.palette.primary.main, // Set the background color to primary
    transition: "bottom 0.3s ease", // Smooth transition for hiding/showing the menu
  })
);

// root component
const DashboardSideBar: FC<SideNavBarProps> = ({
  showMobileSideBar,
  closeMobileSideBar,
}) => {
  const navigate = useNavigate();
  const [active, setActive] = useState("Dashboard");
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const [menuHidden, setMenuHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleActiveMainMenu = (menuItem: any) => () => {
    setActive(menuItem.title);
    navigate(menuItem.path);
    closeMobileSideBar();
  };

  // Scroll event listener to hide/show the bottom menu on mobile
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setMenuHidden(true); // Hide menu on scroll down
      } else {
        setMenuHidden(false); // Show menu on scroll up
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  // main menus content
  const mainSideBarContent = (
    <Box sx={{ height: "100%" }}>
      <Box sx={{ padding: 2, textAlign: "center" }}>
        <img src="/favicon.png" alt="Skip Logo" width={31} />
      </Box>
      {topMenuList.map((nav, index) => (
        <Box
          key={index}
          sx={{
            padding: 2,
            textAlign: "center",
            cursor: "pointer",
            color: active === nav.title ? "#fff" : "rgba(255, 255, 255, 0.7)", // Text color in white or slightly transparent
            fontWeight: active === nav.title ? "bold" : "normal", // Make the active label bold
          }}
          onClick={handleActiveMainMenu(nav)}
        >
          <nav.Icon />
          <Box
            sx={{
              fontSize: 12,
              fontWeight: "bold", // Make labels bold
              marginTop: 1,
            }}
          >
            {nav.title}
          </Box>
        </Box>
      ))}
    </Box>
  );

  // for mobile device
  if (downMd) {
    return (
      <BottomMenu
        value={active}
        onChange={(event, newValue) => {
          setActive(newValue);
          const selectedNav = topMenuList.find((nav) => nav.title === newValue);
          if (selectedNav) navigate(selectedNav.path);
        }}
        hidden={menuHidden} // Pass the hidden state to the BottomMenu
        sx={{
          "& .Mui-selected": {
            color: "#fff !important", // Ensure selected icon and label are white
          },
          "& .MuiBottomNavigationAction-root": {
            color: "rgba(255, 255, 255, 0.7)", // Set unselected icons and labels to slightly transparent white
            minWidth: "auto", // Ensure the actions are properly centered
          },
          "& .MuiBottomNavigationAction-label": {
            fontWeight: "bold", // Make labels bold
            textAlign: "center", // Center the label text
          },
        }}
      >
        {topMenuList.map((nav, index) => (
          <BottomNavigationAction
            key={index}
            label={nav.title}
            value={nav.title}
            icon={<nav.Icon />}
            sx={{
              color: active === nav.title ? "#fff" : "rgba(255, 255, 255, 0.7)", // Ensure active icon and label are white
              alignItems: "center", // Center the icon and label vertically
              justifyContent: "center", // Center the icon and label horizontally
            }}
          />
        ))}
      </BottomMenu>
    );
  }

  return <MainMenu>{mainSideBarContent}</MainMenu>;
};

export default DashboardSideBar;
