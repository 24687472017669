import mCar from "./login/car-finance-rafiki.svg";
import mWallet from "./login/e-wallet-rafiki.svg";
import mFinance from "./login/finance-rafiki.svg";
import mSaving from "./login/saving-money.svg";
import mMoney from "./login/money-income-rafiki.svg";
import mEducation from "./login/education-rafiki.svg";

import mChickenFarm from "./login/chickenfarm-rafiki.svg";
import mClimateChange from "./login/climatechange-bro.svg";
import mFarmersMarket from "./login/farmersmarket-bro.svg";
import mFarming from "./login/farming.svg";
import mForest from "./login/forest-bro.svg";
import mGlobalWarming from "./login/globalwarming-rafiki.svg";

// Login Slider
export const Car = mCar;
export const Wallet = mWallet;
export const Finance = mFinance;
export const Saving = mSaving;
export const Money = mMoney;
export const Education = mEducation;

export const ChickenFarm = mChickenFarm;
export const ClimateChange = mClimateChange;
export const FarmersMarket = mFarmersMarket;
export const Farming = mFarming;
export const Forest = mForest;
export const GlobalWarming = mGlobalWarming;


